<template>
    <div class="about h-screen items-center bg-gradient-to-r from-gray-300 to-blue-200">
    <span class="decoration-clone text-black text-5xl">
        This is a gallery page.
    </span>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>